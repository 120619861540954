.app-layout {
  width: 100vw;

  align-items: center;
  display: flex !important;
  flex-direction: column;
  padding: 20px;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .app-top-bar {
    width: 100%;
    height: 64px;

    display: flex;
    align-items: center;

    .app-page-title {
      color: #201a1b;
      flex: 1;
      font-weight: 600;
      text-align: center;
    }
  }

  .app-content {
    width: 100%;
    height: calc(100% - 132px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .app-actions {
    margin-top: auto;
    padding-top: 8px;
    width: 100%;

    .primary-action {
      width: 100%;
      height: 52px;
      border-radius: 8px;
    }
  }
}
