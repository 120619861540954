.wrapper {
  align-items: center;
  display: flex;

  padding-top: 2em;
}

.agree-wrapper {
  border: 1px solid #5B5B5B;
  border-radius: 4px;

  display: flex;
  margin-bottom: 1em;
  padding: 16px;

  .agree-text {
    margin-left: 1em;
  }
}
