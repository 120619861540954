.content {
  padding: 0 12px;
  position: relative;

  .image-wrapper {
    display: flex;
    min-height: 280px;

    > svg {
      margin: auto;
      width: 100%;
    }
  }
  
  .caption {
    font-size: 18px !important;
    text-align: center;
    margin-top: 2em;
  }

  ul.indicators {
    padding-inline-start: unset;

    position: absolute;
    top: 265px;
    left: 50%;
    transform: translateX(-50%);

    .active::before {
      background: #FF9F0A;
    }
  }

  .ar-feature {
    margin-top: 1.5em;

    .ar-feature-button {
      padding: 16px 0;
  
      background: linear-gradient(180deg, #FFE70A 0%, #FF800A 100%);
      box-shadow: 3px 4px 6px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      display: block;

      font-size: 16px;
      font-weight: 500;
      text-align: center;

      width: 100%;

      &.Mui-disabled {
        background: #C7C1CB;
      }
  
      svg {
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }

  .small-caption {
    color: #49494C;
    font-size: 12px;
    margin-top: 1em;
    text-align: center;
  }
}
