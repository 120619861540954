.paragraph-wrapper {
  text-align: left;
  width: 100%;
}

.subtitle {
  margin: 0.5em 0 !important;
}

.text {
  margin-bottom: 1em !important;
}
