
.error-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  width: 100%;

  .content {
    margin: auto 0;

    .image-wrapper {
      margin-bottom: 2em;
    }

    .content-title {
      margin-bottom: .3em;
    }
  }
}
