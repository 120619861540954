.survey-page {
  .survey-page-title {
    width: 100%;
    margin-top: 2em;
    text-align: center;
  }

  .survey-root {
    background: #fff;

    .survey-question-description,
    .survey-header,
    .survey-action-bar {
      display: none;
    }

    .survey-page-root {
      padding: 0;

      .survey-row {
        margin: 16px 0;
      }

      .survey-page-panel {
        border: 1px solid #e7e1e5;
        border-radius: 16px;
        box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.36);
        overflow: hidden;

        .sd-question__erbox .sv-string-viewer {
          color: #e60a3e;
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          font-weight: 400;
          font-size: 0.875rem;
        }
      }

      .survey-panel-header {
        width: 100%;
        min-height: 72px;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
        background-color: unset;
      }

      .survey-question {
        padding: 0;

        .sd-question__erbox {
          display: none;
        }
      }

      .survey-panel-title {
        display: flex;
        padding: 16px;
        flex: 1;
        background: #5A4E62;
        // border-radius: 16px;

        transition: all 0.15s ease-in;

        &:before {
          display: none;
        }

        [data-key='q_num'] {
          width: 40px;
          height: 40px;

          background: #fff;
          border-radius: 100%;

          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          font-weight: 400;
          font-size: 1.125rem;
          line-height: 2;
          color: #685972;
          margin: auto;
          padding: 0;

          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.15s ease-in;
        }

        .sv-string-viewer {
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.25;
          display: flex;
          align-items: center;
          color: #fff;
          flex: 1;
          padding: 0 16px;
          transition: all 0.15s ease-in;
        }

        [data-key='num-sp'],
        [data-key='req-sp'],
        [data-key='req-text'] {
          display: none;
        }

        .survey-header-caret {
          width: 40px;
          height: 40px;

          color: #fff;
          border-radius: 100%;

          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.15s ease-in;

          .up-icon {
            display: none;
          }
        }
      }

      .survey-question-item {
        height: 56px;
        display: flex;
        align-items: center;
        padding: 12px 16px;
      }

      .survey-radio-decorator {
        width: 24px;
        height: 24px;

        border-radius: 100%;
        box-shadow: none;
        border: 2px solid #323232;
        background: transparent;

        &:after {
          width: 12px;
          height: 12px;

          background: #FF9F0A;
        }
      }

      .survey-checkbox-decorator {
        width: 18px;
        height: 18px;

        border: 2px solid #49454f;
        border-radius: 2px;
        background: transparent;
        box-shadow: none;

        .sd-checkbox__svg use {
          fill: white;
        }
      }

      .survey-checkbox-checked {
        background: #FFF3E0;

        .survey-checkbox-decorator {
          background: #FF9F0A;
          border-radius: 2px;
          border: none;
        }
      }

      .survey-radio-checked {
        background: #FFF3E0;

        .survey-radio-decorator {
          border: 3px solid #FF9F0A;
        }
      }

      .survey-base-label {
        gap: 16px;
        align-items: center;
        justify-content: center;
      }

      .survey-question-label {
        .sv-string-viewer {
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          line-height: 150%;
          letter-spacing: 0.5px;
          color: #1c1b1f;
        }
      }
    }
  }

  .thankyou-illustration {
    width: 100%;
    height: 320px;

    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: auto;
      height: 289px;
    }
  }
}
